.attribute-workspace-diff {
  font-size: 80%;
}

.schema-validation-result {
  .validation-error-list, .validation-info-list {
    padding-left: 10px;
    border-left:  2px solid grey;
    p.hint {
      font-size: 95%;
      opacity:   .7;
      margin:    .5rem 0;
    }
    ul {
      list-style-type: none;
      padding-left:    0;
    }
  }
  .validation-error-list {
    border-color: red;
  }
}
