body {
  background: #eee;
}

.app-layout-wrapper {
  display:        block;
  min-height:     100vh;
  padding-bottom: 10px;
}

.hide-on-mobile {
  @media(max-width: 539px) {
    display: none;
  }
}

.app-layout {
  max-width:  960px;
  background: white;
  @media(min-width: 540px) {
    margin:        30px;
    border-radius: 2px;
  }
  @media(min-width: 740px) {
    margin: 40px 60px;
  }
  @media(min-width: 1060px) {
    margin: 40px auto;
  }
  .app-header, .app-content, .app-footer {
    background:    transparent;
    padding-left:  35px;
    padding-right: 35px;
  }
}

.app-header {
  border-bottom: 1px solid #f0f0f0;
  display:       flex;
  padding-top:   10px;
}

.app-footer {
  display:    flex;
  border-top: 1px solid #f0f0f0;
}

.app-content {
  padding-top: 20px;
}

.main-nav {
  flex:          1;
  display:       flex;
  border-bottom: none;
  margin-top:    2px;
  [data-icon] {
    margin-right: .5rem;
  }
  .ant-menu-item {
    line-height:  56px;
    margin-right: 5px !important;
  }
}
