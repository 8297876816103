$main-layout-padding: 35px;

@mixin stretch-grid-margins($direction, $child-selector) {
  // We want to hit the first child table, but not embedded "inside" tables such as expanded row views (from Ant table)
  > .ant-table,
  > * > .ant-table,
  > * > * > .ant-table,
  > * > * > * > .ant-table {
    margin-#{$direction}: - $main-layout-padding;
    max-width:            none;
    tr {
      td:#{$child-selector + "-child"}, th:#{$child-selector + "-child"} {
        padding-#{$direction}: $main-layout-padding
      }
    }
  }
}

.stretch-grid-left {
  @include stretch-grid-margins('left', 'first');
}

.stretch-grid-right {
  @include stretch-grid-margins('right', 'last');
}

.stretch-grid {
  @extend .stretch-grid-left;
  @extend .stretch-grid-right;
}

.ant-table {
  background: none;
}

.ant-table-thead > tr > th {
  //background-color: transparent;
  background: none;
}

.input-row-wrapper {
  .input-row {
    display:       flex;
    align-items:   center;
    margin-bottom: .75rem;
    > div {
      display:        flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: .5rem;
      }
      > label:not(.ant-checkbox-wrapper) {
        font-size: 80%;
      }
    }
  }
}

.grid-row-error {
  background: rgba(map-get($colors, "red"), .15);
}
