@import "../../node_modules/antd/dist/antd.min.css";

@import "./variables";

@import "./hljs";
@import "./layout";
@import "./status";
@import "./tables";

.validation-errors-alert {
  p {
    font-size:     90%;
    margin-bottom: .4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.button-label-badge {
  transform: translate(4px, -4px);
}

.form-section {
  padding-bottom: .75rem;
  margin-bottom:  1.5rem;
  border-bottom:  1px solid rgba(0, 0, 0, 0.1);
}

code {
  font-size: 90%;
  color:     #ab5555;
}

.error-trace-line {
  font-size:   80%;
  white-space: normal;
  word-break:  break-all;
}

.link-button {
  background:      transparent;
  border:          none;
  padding:         0;
  color:           inherit;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.clickable-row {
  cursor: pointer;
}
