$popunder-padding: 10px;
.location-summary {
  display:  inline-block;
  position: relative;
  .popunder {
    z-index:        -1;
    position:       absolute;
    opacity:        0;
    transition:     opacity .2s;
    pointer-events: none;
    left:           - $popunder-padding;
    top:            - ($popunder-padding - 1);
    right:          - $popunder-padding;
    padding:        (25 + $popunder-padding) $popunder-padding $popunder-padding;
    border-radius:  5px;
    background:     white;
    border:         1px solid rgba(black, .1);
    box-shadow:     0 2px 3px rgba(black, .05);
    button {
      text-align: left;
      svg {
        /* Button icon */
        transform:    scale(.8);
        margin-right: 5px;
        width: 15px;
      }
    }
  }
  .ant-tag {
    z-index:      100;
    margin-right: 0;
  }
  &:hover {
    z-index: 1;
    cursor:  pointer;
    .popunder {
      opacity:        1;
      pointer-events: initial;
    }
  }
}
