.status-indicator {
  width:      16px !important;
  text-align: center;
  &.status-error {
    color: map-get($colors, "red");
  }
  &.status-success {
    color: map-get($colors, "green");
  }
}

.message-classification {
  margin: 0 .6rem;
  code {
    color: inherit;
  }
  &.incoming {
    color: map-get($colors, "grey");
  }
  &.outgoing {
    color: map-get($colors, "blue");
  }
}
