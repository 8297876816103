.copy-clipboard-icon {
  cursor:  pointer;
  display: inline-block;
  padding: .4rem;
  margin:  -.4rem 0 -.3rem .2rem;

  opacity: .5;
  &:hover {
    opacity: 1;
  }
}
