@import "../../styles/variables";

.task-attributes-summary {
  .buttons {
    display:     flex;
    align-items: center;
  }
  .pending-info {
    //font-size: 90%;
    //
    .hint {
      font-size:     90%;
      line-height:   1.5;
      opacity:       .6;
      margin-bottom: .3rem;
    }
    .list {
      > * {
        &:not(:last-child) {
          &:after {
            display:      inline-block;
            content:      ', ';
            margin-right: 5px;
            opacity:      .6;
          }
        }
      }
    }
  }
  margin-bottom: 1rem;
}
